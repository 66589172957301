import { Web3ContextProvider } from 'blockchain/Web3Context'
import Layout from 'components/layout'
import { AppProps } from 'next/app'
import { useRouter } from 'next/router'
import { NextPage } from 'next/types'
import { ReactElement, ReactNode, useEffect } from 'react'
import { ToastProvider } from 'react-toast-notifications'

import * as ga from '../helpers/ga'
import '../styles/global.css'

type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

export default function App({ Component, pageProps }: AppPropsWithLayout) {
  const router = useRouter()

  const getLayout = Component.getLayout || ((page) => page)

  useEffect(() => {
    const handleRouteChange = (url) => {
      ga.pageview(url)
    }
    //When the component is mounted, subscribe to router changes
    //and log those page views
    router.events.on('routeChangeComplete', handleRouteChange)

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  return (
    <>
      <ToastProvider placement="bottom-right" autoDismiss>
        <Web3ContextProvider>{getLayout(<Component {...pageProps} />)}</Web3ContextProvider>
      </ToastProvider>
    </>
  )
}
