export const formatNum = (val: number, numFractionDigits: number) => {
  return val
    ? val.toLocaleString('en-US', {
        minimumFractionDigits: numFractionDigits ?? 0,
        maximumFractionDigits: numFractionDigits ?? 0,
      })
    : 'N/A'
}

export const formatSigNum = (val: number, numSigFig: number) => {
  return val
    ? val.toLocaleString('en-US', {
        minimumSignificantDigits: numSigFig ?? 2,
        maximumSignificantDigits: numSigFig ?? 2,
      })
    : 'N/A'
}

export const formatEth = (eth: number) => `${formatNum(eth, 3)}Ξ`
export const formatCurrency = (amount: number) => `$${formatNum(amount, 2)}`

export const getPageClassnames = (pathname: string) => {
  if (pathname.includes('/wrapped')) return 'blackBg'
  if (pathname.includes('/loading')) return 'limeBg'
  if (pathname.includes('/premint')) return 'purpleBg'
  return ''
}

// could do this with env vars but i'm lazy
const FAKE_PROD_ENABLED = false

export const isDev = !FAKE_PROD_ENABLED && process.env.NODE_ENV === 'development'

export const isProd = FAKE_PROD_ENABLED || process.env.NODE_ENV === 'production'
