import Portis from '@portis/web3'
import WalletConnectProvider from '@walletconnect/web3-provider'
import Authereum from 'authereum'
import { IChainData } from 'blockchain/types'
import { getChainData } from 'blockchain/utils'
import { BigNumber, providers } from 'ethers'
import Fortmatic from 'fortmatic'
import { TK_ADDRESS } from 'helpers/constants'
import LogRocket from 'logrocket'
import { useRouter } from 'next/router'
import React, { useCallback, useContext, useEffect, useMemo, useReducer, useState } from 'react'
import { useToasts } from 'react-toast-notifications'
import WalletLink from 'walletlink'
import Web3Modal from 'web3modal'

const INFURA_ID = process.env.NEXT_PUBLIC_INFURA_ID

const providerOptions = {
  // TK doesnt want > 2 providers sadge
  walletconnect: {
    package: WalletConnectProvider, // required
    options: {
      infuraId: INFURA_ID, // required
    },
  },
  'custom-walletlink': {
    display: {
      logo: '/images/coinbase.jpeg',
      name: 'Coinbase',
      description: 'Connect to Coinbase Wallet (not App)',
    },
    options: {
      appName: 'Coinbase', // Your app name
      networkUrl: `https://mainnet.infura.io/v3/${INFURA_ID}`,
      chainId: 1,
    },
    package: WalletLink,
    connector: async (_: any, options: any) => {
      const { appName, networkUrl, chainId } = options
      const walletLink = new WalletLink({
        appName,
      })
      const provider = walletLink.makeWeb3Provider(networkUrl, chainId)
      await provider.enable()
      return provider
    },
  },
  authereum: {
    package: Authereum, // required
  },
  fortmatic: {
    package: Fortmatic, // required
    options: {
      key: 'pk_live_776135D102CBB1E8', // required
    },
  },
}

const portisOption = {
  package: Portis, // required
  options: {
    id: 'd2a7f55e-72d6-4e6d-bd7a-2392df8d1fd4', // required
  },
}

let web3Modal: any
if (typeof window !== 'undefined') {
  if (window.innerWidth > 500) {
    providerOptions['portis'] = portisOption
  }
  web3Modal = new Web3Modal({
    network: 'mainnet', // optional
    cacheProvider: true,
    providerOptions, // required
  })
}

type StateType = {
  provider?: any
  web3Provider?: providers.Web3Provider
  address?: string
  chainId?: number
  ens?: string
}

type ActionType =
  | {
      type: 'SET_WEB3_PROVIDER'
      provider?: StateType['provider']
      web3Provider?: StateType['web3Provider']
      address?: StateType['address']
      ens?: string
      chainId?: StateType['chainId']
    }
  | {
      type: 'SET_ADDRESS'
      address?: StateType['address']
      ens?: StateType['ens']
    }
  | {
      type: 'SET_CHAIN_ID'
      chainId?: StateType['chainId']
    }
  | {
      type: 'RESET_WEB3_PROVIDER'
    }

const initialState: StateType = {
  provider: undefined,
  web3Provider: undefined,
  address: undefined,
  chainId: undefined,
  ens: undefined,
}

function reducer(state: StateType, action: ActionType): StateType {
  switch (action.type) {
    case 'SET_WEB3_PROVIDER':
      return {
        ...state,
        provider: action.provider,
        web3Provider: action.web3Provider,
        address: action.address,
        ens: action.ens,
        chainId: action.chainId,
      }
    case 'SET_ADDRESS':
      return {
        ...state,
        ens: action.ens,
        address: action.address,
      }
    case 'SET_CHAIN_ID':
      return {
        ...state,
        chainId: action.chainId,
      }
    case 'RESET_WEB3_PROVIDER':
      return initialState
    default:
      throw new Error()
  }
}

type IWeb3Context = StateType & {
  connectWallet: () => Promise<void>
  disconnectWallet: () => Promise<void>
  chainData?: IChainData
  isConnected: boolean
  balance?: BigNumber
  isConnecting: boolean
}

export const Web3Context = React.createContext<IWeb3Context>({
  ...initialState,
  connectWallet: async () => {
    throw new Error('No Web3Context found!')
  },
  disconnectWallet: async () => {
    throw new Error('No Web3Context found!')
  },
  isConnected: false,
  isConnecting: false,
})

export const Web3ContextProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const { provider, web3Provider, address, ens, chainId } = state
  const [balance, setBalance] = useState<BigNumber>(BigNumber.from('1'))
  const [isConnecting, setIsConnecting] = useState(false)
  const router = useRouter()
  const { addToast } = useToasts()

  const connectWallet = useCallback(async function () {
    setIsConnecting(true)
    try {
      // This is the initial `provider` that is returned when
      // using web3Modal to connect. Can be MetaMask or WalletConnect.
      let provider
      try {
        provider = await web3Modal.connect()
      } catch (err) {
        console.error(err)
      }
      if (!provider) {
        addToast('Could not connect to provider.', { appearance: 'error' })
        return
      }

      // We plug the initial `provider` into ethers.js and get back
      // a Web3Provider. This will add on methods from ethers.js and
      // event listeners such as `.on()` will be different.
      const web3Provider = new providers.Web3Provider(provider)

      const signer = web3Provider.getSigner()
      const address = await signer.getAddress()

      let ens
      try {
        ens = (await web3Provider.lookupAddress(address)) ?? undefined
      } catch (err) {
        console.error(err)
      }

      LogRocket.identify(address, {
        ens,
      })

      const network = await web3Provider.getNetwork()
      const balance = await web3Provider.getBalance(address)
      if (balance) {
        setBalance(balance)
      }

      dispatch({
        type: 'SET_WEB3_PROVIDER',
        provider,
        web3Provider,
        address,
        ens,
        chainId: network.chainId,
      })

      router.push('/wrapped')
    } catch (err) {
      console.info(err)
    } finally {
      setIsConnecting(false)
    }
  }, [])

  const disconnectWallet = useCallback(
    async function () {
      await web3Modal.clearCachedProvider()
      if (provider?.disconnect && typeof provider.disconnect === 'function') {
        await provider.disconnect()
      }
      dispatch({
        type: 'RESET_WEB3_PROVIDER',
      })
    },
    [provider]
  )

  // Auto connect to the cached provider
  useEffect(() => {
    if (web3Modal.cachedProvider) {
      connectWallet()
    }
  }, [connectWallet])

  // A `provider` should come with EIP-1193 events. We'll listen for those events
  // here so that when a user switches accounts or networks, we can update the
  // local React state with that new information.
  useEffect(() => {
    if (provider?.on) {
      const handleAccountsChanged = async (accounts: string[]) => {
        // eslint-disable-next-line no-console
        console.log('accountsChanged', accounts)
        window.location.reload()
        // dispatch({
        //   type: 'SET_ADDRESS',
        //   address,
        //   ens,
        // })
      }

      // https://docs.ethers.io/v5/concepts/best-practices/#best-practices--network-changes
      const handleChainChanged = (_hexChainId: string) => {
        window.location.reload()
      }

      const handleDisconnect = (error: { code: number; message: string }) => {
        // eslint-disable-next-line no-console
        console.log('disconnect', error)
        disconnectWallet()
      }

      provider.on('accountsChanged', handleAccountsChanged)
      provider.on('chainChanged', handleChainChanged)
      provider.on('disconnect', handleDisconnect)

      // Subscription Cleanup
      return () => {
        if (provider.removeListener) {
          provider.removeListener('accountsChanged', handleAccountsChanged)
          provider.removeListener('chainChanged', handleChainChanged)
          provider.removeListener('disconnect', handleDisconnect)
        }
      }
    }
  }, [provider, disconnectWallet, web3Provider])

  const chainData = useMemo(() => {
    try {
      return getChainData(chainId)
    } catch (e) {
      disconnectWallet().then(() => router.push('/'))
      addToast(e.message, { appearance: 'error', autoDismissTimeout: 10000 })
    }
  }, [chainId])

  const isConnected = Boolean(provider) && Boolean(address)

  return (
    <Web3Context.Provider
      value={{
        provider,
        web3Provider,
        address,
        chainId,
        chainData,
        connectWallet,
        disconnectWallet,
        ens,
        isConnected,
        isConnecting,
        balance,
      }}
    >
      {children}
    </Web3Context.Provider>
  )
}

export const useWeb3Context = () => {
  return useContext(Web3Context)
}
