import supportedChains from './chains'
import { IChainData } from './types'

export function getChainData(chainId?: number): IChainData | undefined {
  if (!chainId) {
    return undefined
  }
  const chainData = supportedChains.filter((chain: any) => chain.chain_id === chainId)[0]

  if (!chainData) {
    throw new Error('ChainId missing or not supported. Please check you are on Mainnet.')
  }

  if (
    chainData.rpc_url.includes('infura.io') &&
    chainData.rpc_url.includes('%API_KEY%') &&
    process.env.NEXT_PUBLIC_INFURA_API_KEY
  ) {
    const rpcUrl = chainData.rpc_url.replace('%API_KEY%', process.env.NEXT_PUBLIC_INFURA_API_KEY)

    return {
      ...chainData,
      rpc_url: rpcUrl,
    }
  }

  return chainData
}

export function ellipseAddress(address = '', width = 4): string {
  if (!address) {
    return ''
  }
  return `${address.slice(0, width)}...${address.slice(-width)}`
}
